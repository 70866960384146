import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function PosturalStrain() {
    return (
        <Layout>
            <Helmet>
                <title>Postural Strain at work and driving - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Postural Strain Related to Work and Driving</h1>
            </Title>
            <section>
                <Text>
                    <Underline>
                        <h2>Problems Related to Work and Driving</h2>
                    </Underline>
                    <p>ONS statistics report that 35 million working days are lost each year to musculoskeletal issues the UK alone. Habitual poor posture can contribute to daily aches and pains both in the workplace and beyond. Whether you work at a desk or you have a more manual occupation, your job may expose you to stresses and strain that can cause you pain.</p>
                    <p>The strains can help cause a number of musculoskeletal disorders in the body, including "sciatica", carpel tunnel syndrome and tennis or golfers elbow. Furthermore, workplace stress can increase pain and discomfort and tension in your low back, across the shoulders and neck, sometimes resulting in regular, persistent headaches.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>Common Causes of Strain in the Workplace</h2>
                    </Underline>
                    <p>Some common causes of strain in the work place include:</p>
                    <ul>
                        <li>Prolonged sitting at a desk</li>
                        <li>Driving long distances</li>
                        <li>Awkward lifting and carrying</li>
                        <li>Overstretching</li>
                        <li>Bending</li>
                        <li>Extended periods of repetitive motion</li>
                        <li>Using a computer without taking breaks</li>
                    </ul>
                </Text>
                <Text>
                    <Underline>
                        <h2>Musculoskeletal Health at Work</h2>
                    </Underline>
                    <p>There are steps you can take to avoid or mitigate common musculoskeletal issues:</p>
                    <ul>
                        <li>Frequent short breaks away from the computer may help avoid back, neck and eye strain.</li>
                        <li>Adjusting your chair and computer display so that the top of the screen is at eye level may be more comfortable for your upper body and neck.</li>
                        <li>When lifting, judge whether you can do this safely alone or need help. Try not to lift items that are too heavy for you on your own (don’t be afraid to ask for assistance). Always keep the item close to your body. Bend your knees and make your legs do the work. Try not to twist your back – turn with your feet.</li>
                        <li>When driving make sure you are positioned comfortably, and take regular breaks on long journey.</li>
                    </ul>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Osteopathy Can Help</h2>
                    </Underline>
                    <p>Osteopaths have clinical training in identifying musculoskeletal disorders, with a thorough understanding of the causes of an ailment, how to treat them and, if necessary, when to refer you to a medical practitioner.</p>
                    <p>During an osteopathic consultation you can discuss the impact work may have on your body and agree on an appropriate cause of action that may help. An osteopath may suggest trying different postures and exercises, and discuss workplace ergonomics and lifting techniques.</p>
                    <p>Osteopaths use a wide range of hands-on techniques, which vary depending on your age, fitness and diagnosis, but often focus on tension, stretching muscles and mobilising your joints.</p>
                    <p>You do not need to consult your GP before you visit an osteopath, although you may wish to do so. Osteopaths can provide you with a fit note if you do need time from work.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default PosturalStrain